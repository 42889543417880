.uniqueCard {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    width:95vw;
    /* margin-left:8%; */
    padding: 20px;
    margin-bottom: 20px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    background-color: #fff;
    transition: transform 0.2s;
  }
  
  .uniqueCard:hover {
    /* transform: translateY(-5px); */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .uniqueTitle {
    margin-bottom: 10px;
    font-size: 1.25rem;
    color: #333;
  }
  
  .uniqueContent {
    font-size: 1rem;
    color: #666;
  }
  

  @media (max-width: 768px){
    .uniqueCard {
        border: 1px solid #e0e0e0;
        /* border-radius: 10px; */
        width:88vw;
        /* margin-left:8%; */
        padding: 20px;
        margin-bottom: 20px;
        /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
        background-color: #fff;
        transition: transform 0.2s;
      }
}