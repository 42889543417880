/* Container for the form */
.form-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 4%;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin-top: 15%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.div-1 {
    margin-left:15%;
}

@media (max-width: 768px){
    .form-container {
    max-width: 85%;
    margin-top: 10%;
    /* margin: 0 auto; */
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
}


/* Styling for form labels */
.form-container label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
}

.form-container-text{
    margin-left: 15%;
}

/* Styling for input fields and textareas */
.form-container input[type="text"],
.form-container input[type="email"],
.form-container input[type="password"],
.form-container textarea {
    width: 70%;
    padding: 10px;
    margin-bottom: 30px;
    margin-left: 15%;
    border: 2px solid #4d4c4c;
    border-radius: 4px;
    font-size: 16px;
    color: #333;
}

/* Styling for input fields and textareas on focus */
.form-container input[type="text"]:focus,
.form-container input[type="email"]:focus,
.form-container input[type="password"]:focus,
.form-container textarea:focus {
    border-color: #66afe9;
    outline: none;
    box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
}

/* Styling for the submit button */
.form-container button {
    width: 75%;
    padding: 10px;
    margin-left: 15%;
    background-color: #4CAF50;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Styling for the submit button on hover */
.form-container button:hover {
    background-color: #45a049;
}

/* Styling for error messages */
.form-container .error {
    color: red;
    margin-top: -15px;
    margin-bottom: 20px;
    font-size: 14px;
}

