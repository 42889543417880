/* body {
  background: linear-gradient(-45deg, #cf0e0e, #967878d2, #246f94d0, #5db65dcb);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100vh;;
}
@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }

  50% {
      background-position: 100% 50%;
  }

  100% {
      background-position: 0% 50%;
  }
}  */


/* body {
  background-image: linear-gradient(45deg, transparent 20%, black 25%, transparent 25%),
                    linear-gradient(-45deg, transparent 20%, black 25%, transparent 25%),
                    linear-gradient(-45deg, transparent 75%, rgb(45, 44, 44) 80%, transparent 0),
                    radial-gradient(gray 2px, transparent 0);
  background-size: 30px 30px, 30px 30px;
} */


/* html {
  --s: 100px;
  margin: 0;
  --_g: #0000 90deg,#046D8B 0;
  background:
    conic-gradient(from 116.56deg at calc(100%/3) 0   ,var(--_g)),
    conic-gradient(from -63.44deg at calc(200%/3) 100%,var(--_g))
    #2FB8AC;
  background-size: var(--s) var(--s);
} */


h1, h2, h3, h4, h5, h6 {
  font-family:monospace;
  font-weight: 700; /* Bold weight for headers */
}

p, a, span, div, input, button {
  font-family:monospace;
  font-weight: 400; /* Regular weight for other elements */
}



/* body {
  background: radial-gradient(1.5em 6.28571em at 1.95em, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.25) 50%, rgba(255, 255, 255, 0.25) 55%, rgba(255, 255, 255, 0) 55%) 0 0, radial-gradient(1.5em 6.28571em at -0.45em, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.25) 50%, rgba(255, 255, 255, 0.25) 55%, rgba(255, 255, 255, 0) 55%) 1.5em 5.5em, radial-gradient(2.3em 4.57143em at 2.99em, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.3) 55%, rgba(255, 255, 255, 0) 55%) 0 0, radial-gradient(2.3em 4.57143em at -0.69em, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.3) 55%, rgba(255, 255, 255, 0) 55%) 2.3em 4em, radial-gradient(3.5em 6.28571em at 4.55em, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.25) 50%, rgba(255, 255, 255, 0.25) 55%, rgba(255, 255, 255, 0) 55%) 0 0, radial-gradient(3.5em 6.28571em at -1.05em, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.25) 50%, rgba(255, 255, 255, 0.25) 55%, rgba(255, 255, 255, 0) 55%) 3.5em 5.5em, radial-gradient(#15ffa5, #00ced1);
  background-color: mediumspringgreen;
  background-size: 1.5em 11em, 1.5em 11em, 2.3em 8em, 2.3em 8em, 3.5em 11em, 3.5em 11em, 100% 100%;
  background-repeat: repeat;
} */


/* body {
  --dot-bg: rgb(109, 101, 101);
  --dot-color: white;
  --dot-size: 1px;
  --dot-space: 22px;
  background:
    linear-gradient(90deg, var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space),
    linear-gradient(var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space),
    var(--dot-color);
} */

body {
  background: 
    radial-gradient(black 3px, transparent 4px),
    radial-gradient(black 3px, transparent 4px),
    linear-gradient(#c6c3c3 4px, transparent 0),
    linear-gradient(45deg, transparent 74px, transparent 75px, #a4a4a4 75px, #a4a4a4 76px, transparent 77px, transparent 109px),
    linear-gradient(-45deg, transparent 75px, transparent 76px, #a4a4a4 76px, #a4a4a4 77px, transparent 78px, transparent 109px),
  #c6c3c3;
  background-size: 109px 109px, 109px 109px,100% 6px, 109px 109px, 109px 109px;
  background-position: 54px 55px, 0px 0px, 0px 0px, 0px 0px, 0px 0px;
}