.pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .page-button {
    margin: 0 5px;
    padding: 8px 16px;
    border: 1px solid #ccc;
    background-color: #f8f8f8;
    color: #333;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .page-button:hover {
    background-color: #007bff;
    color: #fff;
  }
  
  .page-button.active {
    background-color: #007bff;
    color: #fff;
    cursor: default;
  }
  
  .page-button:disabled {
    cursor: default;
    background-color: #007bff;
    color: #fff;
  }
  
  .ellipsis {
    margin: 0 5px;
    padding: 8px 16px;
    color: #666;
    cursor: default;
  }
  
  /* HomePage2.css */
.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
