.card2 {
    width: 40%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background-color: #ffffff;
    /* position:flex; */
    margin: 20px;
    margin-left:10%;
    margin-top: 2%;
    transition: transform 0.2s;
  }
  @media (max-width: 768px){
    .card2 {
        width: 80%;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        background-color: #ffffff;
        margin-left: 4%;
        margin-top: 4%;
        transition: transform 0.1s;
    }
    .card2-footer h4{
      font-size: 10px;
      visibility: visible;
      color: #333;
      margin-left: 0px;
    }

    }
  .card2:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* More pronounced shadow */
  }
  
  .card2-header {
    background-color: #f4f4f4;
    padding: 15px;
    text-align: center;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .card2-header h2 {
    margin: 0;
    font-size: 15px;
  }
  
  .card2-image img {
    width: 30%;
    height: auto;
    display: block;
  }
  
  .card2-body {
    padding: 15px;
    font-size: 1em;
    color: #333;
  }
  
  .card2-footer {
    background-color: #f4f4f4;
    padding: 25px 30px;
    text-align: center;
    border-top: 1px solid #e0e0e0;
    font-size: 0.9em;
    color: #666;
  }

.card2-footer h4{
  margin-left: 28vw;
  /* word-wrap:normal; */
}
  