.uniqueCard {
  width: 95vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 0px;
  background-color: #fff;
  transition: transform 0.2s;
}

.uniqueCard:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.uniqueLeft {
  flex: 1;
  padding-right: 40px;
}

.uniqueRight {
  flex: 0;
  margin-right: 10%;
}

.uniqueIframe {
  width: 420px;
  height: 236.25px;
  border: none;
}

.uniqueTitle {
  margin-bottom: 10px;
  font-size: 1.25rem;
  color: #9b0606;
}

.uniqueTitle2 {
  margin-bottom: 10px;
  font-size: 1.25rem;
  color: #333;
}

.uniqueLink {
  font-size: 1rem;
  color: #007bff;
  text-decoration: none;
}

.uniqueLink:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .uniqueCard {
    width: 88vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e0e0e0;
    padding: 20px;
    margin-bottom: 0px;
    background-color: #fff;
    transition: transform 0.2s;
  }

  .uniqueIframe {
    width: 210px;
    height: 118.125px;
  }

  .uniqueThumbnail {
    width: 150px;
    height: auto;
    border-radius: 30px;
    margin-left: 20%;
  }

  .uniqueRight {
    flex: 0;
    margin-right: 5%;
  }
}
