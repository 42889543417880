.aboutContainer {
    width: 80%;
    margin: 0 auto;
    margin-top: 10%;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .heading {
    text-align: center;
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .subheading {
    font-size: 1.75rem;
    color: #555;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .paragraph {
    font-size: 1.25rem;
    color: #666;
    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  .section {
    margin-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    .aboutContainer {
      width: 95%;
      padding: 10px;
    }
  
    .heading {
      font-size: 2rem;
    }
  
    .subheading {
      font-size: 1.5rem;
    }
  
    .paragraph {
      font-size: 1rem;
    }
  }
  