.card {
  width: 50%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: #ffffff;
  margin-left: 10%;
  margin-top: 4%;
  transition: transform 0.1s;
  display: flex;
  flex-direction: column;
}

.card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* More pronounced shadow */
}

.card-header {
  background-color: #f4f4f4;
  padding: 15px;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
}

.card-header h2 {
  margin: 0;
  font-size: 1.5em;
}

.card-body {
  display: flex;
  padding: 15px;
  font-size: 1em;
  color: #333;
}

.card-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-image img {
  width: 100%;
  height: auto;
  max-width: 150px; /* Adjust the max-width as needed */
}

.card-form {
  flex: 2;
  padding-left: 15px;
}

.card-footer {
  background-color: #f4f4f4;
  padding: 10px 15px;
  text-align: center;
  border-top: 1px solid #e0e0e0;
  font-size: 0.9em;
  color: #666;
}

.form-group {
  margin-bottom: 15px;
  width: 60%;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input {
  width: calc(100% - 10px); /* Adjust to fit within the card */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group input::placeholder {
  color: #6c757d; /* Custom color for the placeholder text */
  opacity: 0.4; /* Adjust the opacity of the placeholder text */
}

@media (max-width: 768px) {
  .card {
    width: 90%;
    margin-left: 5%;
    flex-direction: column;
  }

  .card-body {
    flex-direction: column;
  }

  .form-group {
    width: 100%;
  }
}
