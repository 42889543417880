.top-bar {
  background-color: #272424;
  color: white;
  padding: 10px 20px;
  /* border-radius: 10px; */
  position: fixed;
  width: 100vw;
  left:0;
  top: 0;
  z-index: 1000; /* Ensures the top bar stays above other content */
  text-align: center;
}

.title-section {
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  /* margin-left:35vw; */
}

.top-bar h1 {
  margin: 0;
  padding: 0;
}

.profiles-link {
  color: white;
  text-decoration: none;
  padding: 0 15px;
  /* margin-left: 10vw; */
  /* margin-right:87vw; */
}


.profiles-link:hover {
  text-decoration: underline;
}

.top-bar nav {
  margin-top: 10px;
  /* margin-left:35vw; */
}

.nav-right {
  /* margin-left:15vw; */
}

.top-bar nav a {
  color: white;
  text-decoration: none;
  padding: 0 15px;
}

.top-bar nav a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .top-bar {
    background-color: #333;
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 9px;
    position: fixed;
    width: 89vw;
    top: 0;
    z-index: 1000; /* Ensures the top bar stays above other content */
    text-align: center;
  }

  .profiles-link {
    color: white;
    text-decoration: none;
    padding: 0 15px;
    margin-right: 10vw;
  }

  .title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left:15vw;
  }

  .top-bar nav {
    margin-top: 10px;
    /* margin-right: 3vw; */
    margin-left:5vw;
  }
  .nav-right {
    margin-left:10vw;
  }
}
