.fancy-green-button {
    background-color: #28a745; /* Primary green color */
    border: none; /* Remove default border */
    color: white; /* Text color */
    padding: 8px 16px; /* Padding for the button */
    font-size: 18px; /* Font size */
    font-weight: bold; /* Bold text */
    text-align: center; /* Center the text */
    text-decoration: none; /* Remove underline from text */
    display: inline-block; /* Make the button inline-block */
    border-radius: 8px; /* Rounded corners */
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.fancy-green-button:hover {
    background-color: #218838; /* Darker green on hover */
    transform: translateY(-2px); /* Slightly lift the button on hover */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

.fancy-green-button:active {
    background-color: #1e7e34; /* Even darker green on active */
    transform: translateY(0); /* Reset lift on active */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow on active */
}

.fancy-green-button:focus {
    outline: none; /* Remove default focus outline */
    box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5); /* Custom focus outline */
}

.channel-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    width: 100%;
  }
  
  .channel-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
  }
  
  .channel-title {
    margin-left: 20px;
    font-size: 15px;    
    font-weight: bold;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #649cd3;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  