.div3{
    margin-top: 10vh;
}

.plus-button {
    background-color: #5f615f8f; /* Green background */
    border: none;
    color: white;
    /* padding: -20px; */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    /* margin: 4px 2px; */
    position: absolute;
    margin-left: 10vw;
    margin-top: -1vh;
    /* top:80px; */
    height: 40px;
    /* z-index: 1000; */
    width: 40px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.3s, transform 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .plus-button:hover {
    background-color: #646765;
  }
  
  .plus-button:active {
    background-color: #a0cca0;
    transform: scale(0.95);
  }


  /* FeedPage.css */
.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
