.card3 {
  width: 40%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: #ffffff;
  /* position:flex; */
  margin: 20px;
  margin-left:10%;
  margin-top: 2%;
  transition: transform 0.2s;
  /* display: none; */
}

.card3:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* More pronounced shadow */
}

.card-header3 {
  background-color: #f4f4f4;
  padding: 15px;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
}

.card-header3 h2 {
  margin: 0;
  font-size: 15px;
}

.card-body3 {
  /* display: flex; */
  padding: 15px;
  font-size: 15px;
  color: #333;
}

.card-image3 {
  flex: 1;
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.card-image3 img {
  width: 100%;
  height: auto;
  max-width: 500px; /* Adjust the max-width as needed */
}

.card-form3 {
  flex: 2;
  padding-left: 15px;
}

.card-footer3 {
  background-color: #f4f4f4;
  padding: 10px 15px;
  text-align: center;
  border-top: 1px solid #e0e0e0;
  font-size: 0.9em;
  color: #666;
}

.form-group3 {
  margin-bottom: 15px;
  width: 60%;
}

.form-group3 label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group3 input {
  width: calc(100% - 10px); /* Adjust to fit within the card */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group3 input::placeholder {
  color: #6c757d; /* Custom color for the placeholder text */
  opacity: 0.4; /* Adjust the opacity of the placeholder text */
}


@media (max-width: 768px) {
  .card3 {
    width: 80%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background-color: #ffffff;
    margin-left: 4%;
    margin-top: 4%;
    transition: transform 0.1s;
}

  .card-body3 {
    flex-direction: column;
  }

  .form-group3 {
    width: 100%;
  }
}
